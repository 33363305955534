import React from 'react';

import {
   Code,
   Megaphone,
   TicketDiagonal,
   BoxMultipleSearch,
   WindowDevTools,
   TextContinuous,
   DocumentLock,
   CubeTree,
   CubeQuick,
   Organization,
   ShieldLock,
   Cloud,
   CalendarLock,
   Calculator,
   CallWarning,
   Headset,
   Window,
   BugProhibited,
   Scales,
   Cube,
   StyleGuide,
   Git,
   DocumentText ,
   QuestionCircle
} from "@styled-icons/fluentui-system-regular";

export default {
   "headers": [
      {
         label: 'Platform',
         to: '/platform',
         type: 'links',
         children: [
            {
               label: 'Developer Toolbox',
               //  desc: 'Solutions for Integrating Native Apps',
               value: '/platform/developer-toolbox ',
               to: '/platform/developer-toolbox',
               image: {
                  src: '/icon-set/developer-tools.svg',
                  alt: 'developer toolbox'
               },
            },
            {
               label: 'Uniform Data Model',
               //  desc: 'Unified Api schema for Integration development',
               value: '/platform/uniform-data-model ',
               to: '/platform/uniform-data-model',
               image: {
                  src: '/icon-set/uniform-datamodel.svg',
                  alt: 'uniform datamodel'
               },
            },
            {
               label: 'Continuous Visibility and Monitoring',
               //   desc: 'Tracking and understanding customer activities',
               value: '/platform/continuous-visibility-and-monitoring',
               to: '/platform/continuous-visibility-and-monitoring',
               image: {
                  src: '/icon-set/real-time-scan.svg',
                  alt: 'continous visibility and monitoring'
               },
            },
            {
               label: 'Security and Reliability',
               //     desc: 'Robust security and seamless reliability',
               value: '/platform/security-and-reliability',
               to: '/platform/security-and-reliability',
               image: {
                  src: '/icon-set/data_security.svg',
                  alt: 'security and reliability'
               },
            },
            // {
            //    label: 'Data Security',
            //    desc: 'Secure Data Handling, No Storage Guaranteed',
            //    value: '/platform/data-security ',
            //    to: '/platform/data-security',
            //    image: {
            //       src: '/icon-set/data_security.svg',
            //       alt: 'data security'
            //    },
            // },
            // {
            //    label: 'Reliability and Scale',
            //    desc: 'Built for unmatched security, saclability and reliability',
            //    value: '/platform/reliability-and-scale ',
            //    to: '/platform/reliability-and-scale',
            //    image: {
            //       src: '/icon-set/new icons/scaleable.svg',
            //       alt: 'loading'
            //    },
            // },
            // {
            //    label: 'Deployment Model',
            //    desc: 'Saas or On-Prem, Flexible Deployment Options',
            //    value: '/platform/deployment-model',
            //    to: '/platform/deployment-model',
            //    image: {
            //       src: '/icon-set/deployment-model.svg',
            //       alt: 'scm'
            //    },
            // },
         ],
         value: '/platform',
      },
      {
         label: 'Integrations',
         to: '/integrations',
         type: 'links',
         rootClassName: 'gap-x-8 ',
         children: [
            {
               label: 'Browse All Connectors →',
               //      desc: 'Leverage pre-built chat tools integrations.',
               value: '/integrations',
               to: '/integrations',
               // image: {
               //    src: '/icon-set/container.svg',
               //    alt: 'Browse Connectors'
               // }
            },
            {
               label: 'Source Code ',
               //    desc: 'Integrate with industry leading SCM providers.',
               value: '/integrations/scm',
               to: '/integrations/scm',
               image: {
                  src: '/icon-set/code.svg',
                  alt: 'scm'
               },
               className: 'min-w-[500px]'
            },
            {
               label: 'Ticketing',
               //   desc: 'Ready made integration Ticketing and ITSM providers.',
               value: '/integrations/ticketing',
               to: '/integrations/ticketing',
               image: {
                  src: '/icon-set/unz_Ticketing.svg',
                  alt: 'ticketing'
               },
            },
            {
               label: 'Packages and Container Registries',
               //        desc: 'Out of the box package management.',
               value: '/integrations/pcr',
               to: '/integrations/pcr',
               image: {
                  src: '/icon-set/container.svg',
                  alt: 'container registry'
               },
            },
            {
               label: 'Communications',
               //      desc: 'Leverage pre-built chat tools integrations.',
               value: '/integrations/communications',
               to: '/integrations/communications',
               image: {
                  src: '/icon-set/comms.svg',
                  alt: 'communication'
               },
            },
            {
               label: 'Incident Management',
               //      desc: 'Leverage pre-built chat tools integrations.',
               value: '/integrations/Incident-Management',
               to: '/integrations/Incident-Management',
               image: {
                  src: '/icon-set/code.svg',
                  alt: 'Incident-Management'
               }
            },
            {
               label: 'Vulnerability Management',
               //      desc: 'Leverage pre-built chat tools integrations.',
               value: '/integrations/vulnerability-management',
               to: '/integrations/vulnerability-management',
               image: {
                  src: '/icon-set/container.svg',
                  alt: 'Vulnerability Management'
               }
            },
            {
               label: 'Identity',
               //      desc: 'Leverage pre-built chat tools integrations.',
               value: '/integrations/Identity-Management',
               to: '/integrations/Identity-Management',
               image: {
                  src: '/icon-set/unz_Ticketing.svg',
                  alt: 'Identity-Management'
               },
            },
            {
               label: 'Observability',
               //      desc: 'Leverage pre-built chat tools integrations.',
               value: '/integrations/Observability',
               to: '/integrations/Observability',
               image: {
                  src: '/icon-set/unz_Ticketing.svg',
                  alt: 'Observability'
               },
            },
            {
               label: 'Public Cloud',
               //      desc: 'Leverage pre-built chat tools integrations.',
               value: '/integrations/public-cloud',
               to: '',
               image: {
                  src: '/icon-set/cloud.svg',
                  alt: 'Public Cloud'
               },
               extra: 'Coming Soon'
            },
            {
               label: 'Gen AI',
               //      desc: 'Leverage pre-built chat tools integrations.',
               value: '/integrations/gen-ai',
               to: '',
               image: {
                  src: '/icon-set/ai-generated-code.svg',
                  alt: 'Gen AI'
               },
               extra: 'Coming Soon'
            },
            {
               label: 'SIEM',
               //      desc: 'Leverage pre-built chat tools integrations.',
               value: '/integrations/SIEM',
               to: '',
               image: {
                  src: '/icon-set/unz_Ticketing.svg',
                  alt: 'SIEM'
               },
                extra: 'Coming Soon'
            },
            {
               label: 'Blob Storage',
               //      desc: 'Leverage pre-built chat tools integrations.',
               value: '/integrations/blob-storage',
               to: '',
               image: {
                  src: '/icon-set/deployment-model.svg',
                  alt: 'Privacy'
               },
               extra: 'Blob Storage'
            },
            {
               label: 'File Storage',
               //      desc: 'Leverage pre-built chat tools integrations.',
               value: '/integrations/file-storage',
               to: '',
               image: {
                  src: '/icon-set/deployment-model.svg',
                  alt: 'File Storage'
               },
               extra: 'Coming Soon'
            },
            {
               label: 'EDR & XDR',
               //      desc: 'Leverage pre-built chat tools integrations.',
               value: '/integrations/EDR&XDR',
               to: '',
               image: {
                  src: '/icon-set/comms.svg',
                  alt: 'EDR & XDR'
               },
               extra: 'Coming Soon',
            },
            {
               label: 'Compliance',
               //      desc: 'Leverage pre-built chat tools integrations.',
               value: '/integrations/compliance',
               to: '',
               image: {
                  src: '/icon-set/code.svg',
                  alt: 'Compliance'
               },
               extra: 'Coming Soon'
            },
          

         ],
         value: '/integrations',
      },
      {
         label: 'Services',
         to: '/services',
         type: 'links',
         children: [],
         value: '/services',
      },
      {
         label: "Use Cases",
         to: '/use-cases',
         type: 'links',
         children: [
            {
               label: 'Source Code Static Analysis',
               // desc: 'Static analysis of source code involves examining the code without executing it',
               value: '/use-cases/sourcecode-staticanalysis',
               to: '/use-cases/sourcecode-staticanalysis',
               icon: Code,
            },
            {
               label: 'Source Composition Analysis',
               // desc: 'Our tool examines and audits the composition of your software dependencies identifying potential vulnerabilities and ensuring compliance with licensing and security standards',
               value: '/use-cases/source-composition-analysis',
               to: '/use-cases/source-composition-analysis',
               icon: ShieldLock,
            },
            {
               label: 'API Security Testing',
               // desc: 'A process to assess the security vulnerabilities and potential risks associated with APIs, ensuring robust protection against unauthorized access, data breaches, and other security threats.',
               value: '/use-cases/api-security-testing',
               to: '/use-cases/api-security-testing',
               icon: ShieldLock,
            },
            //  {
            //       label: 'Application Security Posture Management',
            //        // desc: 'A framework and set of practices to continuously assess, prioritize, and improve the overall security posture of applications, ensuring they meet compliance requirements and mitigate potential risks effectively. ',
            //        value: '/use-cases/application-security',
            //        to: '/use-cases/application-security',
            //        icon: CalendarLock,
            //     },
            {
               label: 'Cloud Security Posture Management',
               // desc: 'Tools and practices to monitor and ensure the security configuration and compliance of cloud resources, protecting against threats and vulnerabilities in cloud environments.',
               value: '/use-cases/cloud-security',
               to: '/use-cases/cloud-security',
               icon: Cloud,
            },
            {
               label: 'SOAR',
               // desc: ' A technology stack designed to streamline security operations by integrating security tools and automating incident response workflows, enhancing efficiency and response times to cyber threats. ',
               value: '/use-cases/soar',
               to: '/use-cases/soar',
               icon: ShieldLock,
            },
            {
               label: 'SIEM',
               // desc: ' A technology that provides real-time analysis of security alerts generated by applications and network hardware, enabling early detection of security incidents and improving response times.',
               value: '/use-cases/siem ',
               to: '/use-cases/siem',
               icon: ShieldLock,
            },
            // {
            //    label: 'APM and Observability',
            //   // desc: 'ools and practices for monitoring and understanding the performance and behavior of applications, infrastructure, and services in real-time, enabling proactive management and optimization.',
            //    value: '/use-cases/apm-observability',
            //    to: ' ',
            //    icon: ShieldLock,
            // },
            // {
            //    label: 'CI/CD',
            //  //  desc: 'A software development practice where code changes are automatically built, tested, and deployed to production environments, ensuring rapid and reliable delivery of applications with increased efficiency and reduced errors.',
            //    value: '/use-cases/ci-cd',
            //    to: ' ',
            //    icon: ShieldLock,
            // },
            {
               label: 'Incident Management',
               //   desc: 'The process of reacting to and managing a security breach or cyberattack swiftly and effectively to minimize damage and restore normal operations as quickly as possible.',
               value: '/use-cases/incident-management ',
               to: '/use-cases/incident-management',
               icon: CallWarning,
            },
            // {
            //    label: 'Privacy and Data Protection',
            // //   desc: ' Measures and practices implemented to safeguard sensitive information from unauthorized access, ensuring compliance with privacy regulations and maintaining user trust.',
            //    value: '/use-cases/privacy-data-protection',
            //    to: ' ',
            //    icon: ShieldLock,
            // },
            {
               label: 'Compliance Management',
               //   desc: 'The process of ensuring that an organization adheres to relevant laws, regulations, and standards through policies, procedures, and controls, reducing legal and operational risks.',
               value: '/use-cases/compliance-management',
               to: '/use-cases/compliance-management',
               icon: ShieldLock,
            },
         ],
         value: '/use-cases',
      },
      {
         label: 'Pricing',
         to: '/pricing',
         type: 'links',
         children: [],
         value: '/pricing',
      },
      {
         label: 'Docs',
         to: 'https://docs.unizo.ai',
         target: '_blank',
         type: 'links',
         children: [],
         value: 'https://docs.unizo.ai',
      },
      {
         label: 'Resources',
         to: '/resources',
         type: "links",
         children: [
            {
               label: 'Blogs',
               //      desc: 'An ROI (Return on Investment) calculator is a tool designed to help businesses and individuals assess the potential financial benefits of an investment or project.',
               value: '/resources/blogs',
               to: 'https://blog.unizo.ai/',
               target: '_blank',
               icon: DocumentText ,
            },
            {
               label: 'ROI Calculator',
               //      desc: 'An ROI (Return on Investment) calculator is a tool designed to help businesses and individuals assess the potential financial benefits of an investment or project.',
               value: '/resources/roi-calculator ',
               to: ' ',
               icon: Calculator,
            },
            {
               label: 'Unizo for startups',
               //     desc: 'A comprehensive support platform offering tailored resources, mentorship, and networking opportunities to empower startups in navigating challenges and accelerating growth.',
               value: '/resources/unizo-for-startups ',
               to: '/unizo-for-startups',
               icon: Organization,
            },

            // {
            //    label: 'Unified API vs iPaaS',
            //    //     desc: 'Unified API provides a consolidated interface for seamless access to integrated functionalities, while iPaaS facilitates cloud-based integration between diverse applications, promoting agility and scalability in data exchange and automation. ',
            //    value: '/resources/unified-apivsipaas',
            //    to: ' ',
            //    icon: Scales,
            // },
            {
               label: 'FAQs',
               //     desc: 'Unified API provides a consolidated interface for seamless access to integrated functionalities, while iPaaS facilitates cloud-based integration between diverse applications, promoting agility and scalability in data exchange and automation. ',
               value: '/resources/FAQs',
               to: '/faqs',
               icon: QuestionCircle,
            },
         ],
         value: '/resources',
      },
      // {
      //    label: 'Blogs',
      //    value: '/blogs',
      //    type: "links",
      // }
   ],
   "footers": {
      socialLinks: [
         {
            name: 'github', to: 'https://github.com/unizo-io', type: 'github', target: '_blank'
         },
         {
            name: 'linkedIn', to: 'https://www.linkedin.com/company/unizoio/', type: 'linkedIn', target: '_blank'
         },
         {
            name: 'x', to: 'https://x.com/Unizoio', type: 'x', target: '_blank'
         },
         {
            name: 'youTube', to: 'https://www.youtube.com/@unizo-io', type: 'youTube', target: '_blank'
         },
      ],
      bottomLinks: [
         {
            name: ' Terms of Service', to: '/terms-of-service',
         },
         {
            name: 'Privacy Policy', to: '/privacy-policy',
         }
      ],
      topLinks: [
         {
            name: 'PRODUCT',
            links: [
               { name: 'Platform', to: '/platform/developer-toolbox' },
               { name: 'Integrations', to: '/integrations' },
               { name: 'Pricing', to: '/pricing' },
               { name: 'UseCases', to: '/use-cases/sourcecode-staticanalysis' }
            ]
         },
         {
            name: 'DEVELOPERS',
            links: [
               { name: 'Documentation', to: 'https://docs.unizo.ai/' },
               { name: 'Guides', to: 'https://docs.unizo.ai/guides' },
               { name: 'API Reference', to: 'https://docs.unizo.ai/api#/' },
            ]
         },
         {
            name: 'RESOURCES',
            links: [
               { name: 'Blogs', to:"https://blog.unizo.ai/",target: '_blank'},
               { name: 'ROI Calculator', },
               { name: 'Unizo for startups',to:'/unizo-for-startups'},
               // { name: 'Unified API vs iPaaS', },
               { name: 'FAQs', to: '/faqs' },
               
            ]
         },
         {
            name: 'COMPANY',
            links: [
               { name: 'About Us', to: '/about-us' },
               { name: 'Careers', to: '/careers' },
               { name: 'Contact Us', to: '/lets-chat' },
               
            ]
         }
      ]
   },
   "actions": {
      "login": "https://app.unizo.ai"
   }
}

