import React, { useState, useContext } from 'react';
import { Link } from 'gatsby';
import { AppContext } from '../../context/appContext';
import { Mail } from '@styled-icons/octicons';
import { Phone } from '@styled-icons/boxicons-regular';
import { BottomFooter } from './BottomFooter';


function Links({ name, links }) {


  //To control accordion in footer
  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (href) => {
    setActiveAccordion(prevAccordion => prevAccordion === href ? null : href);
  };

  return (
    <div className="flex flex-col xl:items-start items-center w-full" >
      {name && (
        <h4 className="text-lg mx-auto xl:mx-0 text-gray-900 font-medium mb-7">{name}</h4>
      )}
      <ul className="text-sm flex flex-col xl:block xl:flex-col  mx-auto xl:mx-0  transition-all duration-500 ">
        {links.map(({ name, to, isAccordion, target }, index) => {
          return (
            <li className="mb-6 mx-auto" key={index}>
              <Link
                to={to}
                className="text-gray-600 "
                target={target}
              >
                {name}
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )

}



export default function Footer() {

  const { links: { footers: links } } = useContext(AppContext);

  return (
    <footer className="bg-[#F4F7FF] pt-5 dark:bg-[#191919]">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        {/*Grid*/}
        <div className="grid grid-cols-1 lg:grid-cols-[40%,60%] gap-3 gap-y-8 md:gap-8 py-10 max-w-sm mx-auto sm:max-w-3xl lg:max-w-full">

          <div className="mb-10 flex flex-col items-center lg:block  lg:mb-0">

            <span className='text-gray-900 text-3xl' >About Us</span>

            <p className="py-8 text-sm text-gray-600 lg:max-w-xs text-center leading-6 lg:text-left">
            Unizo empowers SaaS companies to simplify, scale, and accelerate their integrations with its embedded integrations platform. Built for developers and trusted by product teams, Unizo delivers seamless connectivity across tools and providers, transforming integrations into a competitive advantage.
            </p>

            <div class="flex flex-col items-center lg:items-start gap-3 ">
              <span class="font-medium">We'd love to chat</span>
              <div class="flex flex-row gap-5">
                <button class="flex flex-row gap-2 items-center border-0 bg-transparent">
                  <Mail className='h-4 w-4' />
                  <span class="text-sm"><a className='!text-gray-900 hover:underline' href="mailto: sales@unizo.ai" mau="">Contact Support</a></span>
                </button>

                <button class="l-container  flex flex-row gap-2 items-center border-0 bg-transparent">
                  <Phone className='h-4 w-4' />
                  <span class="text-sm text-gray-900">
                    <Link className='!text-gray-900 hover:underline' to="/lets-chat">Contact Us</Link>
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div className='w-full grid grid-cols-2 md:grid-cols-4'>
            {links.topLinks.map(({ name, ...rest }, key) => {
              return (
                <Links name={name} links={rest?.links ?? []} target={rest?.target} key={key} />
              )
            })}
          </div>

        </div>
        <BottomFooter links={links.bottomLinks} socialLinks={links.socialLinks} />
      </div>
    </footer>
  );
}
